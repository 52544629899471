import { getData } from "@/utils/fetch";
import { defineStore } from "pinia";
import debounce from "debounce-promise";
import axios from "../config";
import { useErrorStore } from "./error.store";
import { useModalStore } from "./modal.store";

let debouncedFetch = debounce(getData, 500);

export const useTeleUserStore = defineStore({
  id: "register",
  state: () => ({
    result: {},
    dataById: {},
  }),
  getters: {
    getDataResult(state) {
      return state.result.data;
    },
  },
  actions: {
    async getAll(params) {
      this.result = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        name: params?.name,
        role: params?.role,
        order: params?.order || "username",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("tele_users", dataparams);
        this.result = { ...data.data };
      } catch (error) {
        if (!params.loadmore) {
          this.result = { error };
        }
      }
    },
    async teleById(id) {
      this.dataById = { loading: true };

      try {
        const data = await getData(`tele_users/${id}`);

        this.dataById = data.data;
      } catch (error) {
        this.result = { error };
      }
    },
    async deleteTele(username) {
      const modal = useModalStore();
      try {
        await axios.delete(`tele_users/${username}`);
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error.message;
        console.log(error);
      }
    },
  },
});
