import { createRouter, createWebHistory } from "vue-router";
import AppLayoutAdmin from "../layouts/AppLayoutAdmin.vue";
import DashboardView from "../views/dashboard/DashboardView.vue";
import TeleUserView from "../views/tele_user/TeleUserView.vue";
import LoginView from "@/views/login/LoginView.vue";
import RawSkywaveView from "@/views/raw_skywave/RawSkywaveView.vue";
import UserView from "@/views/user/UserView.vue";
import TeleMessageView from "@/views/tele_message/TeleMessageView.vue";
import RegisterView from "@/views/login/RegisterView.vue";
import LogbookView from "@/views/logbook/LogbookView.vue";
import {
  useAlertStore,
  useAuthStore,
  useScrollStore,
  useSidebarStore,
} from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      authPage: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      authPage: true,
    },
  },
  {
    path: "/",
    name: "AppLayout",
    component: AppLayoutAdmin,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/tele_user",
        name: "tele_user",
        component: TeleUserView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/raw_skywave",
        name: "raw_skywave",
        component: RawSkywaveView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/user",
        name: "user",
        component: UserView,
        meta: {
          requireAdmin: true,
        },
      },
      {
        path: "/tele_message",
        name: "tele_message",
        component: TeleMessageView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/logbook",
        name: "logbook",
        component: LogbookView,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   const alertStore = useAlertStore();
//   const sidebarStore = useSidebarStore();
//   const scroll = useScrollStore();

//   alertStore.clear();
//   sidebarStore.$reset();
//   scroll.$reset();

//   const publicPages = ["/login"];
//   const authRequired = !publicPages.includes(to.path);
//   const authStore = useAuthStore();

//   if (authRequired && !authStore.user) {
//     authStore.returnUrl = to.fullPath;
//     return "/login";
//   } else if (to.name === "login" && authStore.user) {
//     return from.fullPath;
//   } else if (to.name === "user" && authStore.user) {
//     if (authStore.user.currUser.role != "admin") {
//       return "/dashboard";
//     }
//   }
// });

router.beforeEach(async (to, from, next) => {
  const alertStore = useAlertStore();
  const sidebarStore = useSidebarStore();
  const scroll = useScrollStore();

  alertStore.clear();
  sidebarStore.$reset();
  scroll.$reset();

  const authStore = useAuthStore();

  if (to.meta.authPage) {
    if (!authStore.user) {
      next();
    } else {
      next(from);
    }
  }

  if (to.meta.requireAuth) {
    if (authStore.user) {
      next();
    } else {
      next({ name: "login" });
    }
  }

  if (to.meta.requireAdmin) {
    if (authStore.user && authStore.user.currUser.role == "admin") {
      next();
    } else {
      next({ name: "dashboard" });
    }
  }
});

export default router;
