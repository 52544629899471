<template>
  <ModalComponent :id="id" title="Delete Data" widthMax="max-w-sm">
    <template #body>
      <p class="px-6 py-10 text-center font-medium text-base">
        Are you sure you want to delete this data ?
      </p>
      <ModalFooterVue colorClose="btn-darkGray" titleClose="NO">
        <template #accept>
          <ButtonComponent vClass="btn-red" @click="$emit('onDeleteData')">
            YES
          </ButtonComponent>
        </template>
      </ModalFooterVue>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import ModalFooterVue from "./ModalFooter.vue";
import ButtonComponent from "./ButtonComponent.vue";

export default {
  emits: ["onDeleteData"],
  props: {
    id: Number,
  },
  inheritAttrs: false,
  components: {
    ModalComponent,
    ModalFooterVue,
    ButtonComponent,
  },
};
</script>
