<template>
  <div class="main">
    <HeaderTitle title="Telegram User">
      <IconTelegram size="w-11 h-11" fill="#14444f" />
    </HeaderTitle>
    <MainContent>
      <TableTeleUser />
    </MainContent>
  </div>
  <TeleUserDetail />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import IconTelegram from "@/components/icons/IconTelegram.vue";
import TableTeleUser from "./TableTeleUser.vue";
import TeleUserDetail from "./TeleUserDetail.vue";

export default {
  components: {
    HeaderTitle,
    MainContent,
    IconTelegram,
    TableTeleUser,
    TeleUserDetail,
  },
};
</script>
