<template>
  <div
    className="flex pt-20 justify-center min-w-full min-h-screen bg-gray-100"
  >
    <div className=" w-2/3 lg:w-1/3">
      <div class="flex justify-center pb-8 max-w-sm">
        <h1 class="text-2xl font-bold font-sans text-primary">
          Seahub Messaging
        </h1>
      </div>
      <CardComponent>
        <h1
          className="font-semibold text-xl text-center text-primary uppercase"
        >
          Login
        </h1>
        <p class="text-center mb-5 text-gray-500">Sign In to your account</p>
        <AlertErrorComponent
          v-if="alert"
          :message="alert.message.response.data.message"
        />
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors, isSubmitting }"
        >
          <InputWithLabel
            label="Your email"
            name="email"
            type="text"
            placeholder="name@mail.com"
            :error="errors.email"
          />
          <InputWithLabel
            label="Your password"
            name="password"
            type="password"
            placeholder="••••••••"
            :error="errors.password"
          />
          <ButtonComponent vClass="w-full bg-darkGreen" :loading="isSubmitting"
            >Sign In</ButtonComponent
          >
          <span class="text-xs font-semibold text-gray-700"
            >Don't have an account?
            <a class="text-red-700" href="/register">Register</a></span
          >
        </Form>
      </CardComponent>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { useAuthStore, useAlertStore } from "@/store";
import { computed } from "vue";
import CardComponent from "@/components/CardComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import * as Yup from "yup";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";

export default {
  components: {
    CardComponent,
    InputWithLabel,
    ButtonComponent,
    Form,
    AlertErrorComponent,
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is Invalid"),
      password: Yup.string().required("Password is required"),
    });

    const authStore = useAuthStore();
    const alertStore = useAlertStore();

    const alert = computed(() => alertStore.alert);

    async function onSubmit(values) {
      const { email, password } = values;

      await authStore.login(email, password);
    }

    return {
      alert,
      onSubmit,
      schema,
    };
  },
};
</script>
