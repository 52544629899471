<template>
  <ModalComponent :id="id" title="Add New User">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-3">
          <InputWithLabel label="First Name *" type="text" name="firstName" />
          <InputWithLabel label="Last Name" type="text" name="lastName" />
          <InputWithLabel label="Email *" type="email" name="email" />
          <InputWithLabel label="Password *" type="password" name="password" />
          <SelectSearchWithLabel
            label="Role *"
            labelColor="text-black"
            placeholder="Select User Role"
            :data="roleUser"
            :optionsValue="'desc'"
            :optionsKey="'name'"
            v-model:formControlName="role"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
              >Save</ButtonComponent
            >
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { ref } from "vue";
import { useModalStore, useUserStore } from "@/store";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    SelectSearchWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const modal = useModalStore();
    const userStore = useUserStore();

    const roleUser = [
      { name: "user", desc: "User" },
      { name: "admin", desc: "Admin" },
    ];

    const role = ref("");
    const loading = ref(false);

    const acceptModal = async (value) => {
      loading.value = true;
      const { firstName, lastName, email, password } = value;

      await userStore.addUser(firstName, lastName, email, password, role.value);
      loading.value = false;
      modal.closeModal();
      role.value = "";
    };

    return {
      roleUser,
      role,
      acceptModal,
    };
  },
};
</script>

<style></style>
