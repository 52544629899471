<template>
  <CardComponent addClass="max-w-full md:max-w-2xl">
    <div class="flex flex-col gap-3">
      <div class="flex justify-between space-x-1 md:space-x-5 items-center">
        <DatePickerCom
          label="Date"
          labelColor="text-gray-800"
          v-model:formControlName="filter.date"
        />
        <SelectMobileId
          labelColor="text-gray-800"
          v-model:formControlName="filter.mobileId"
        />
        <ButtonComponent
          vClass="btn-amber px-2 py-2 mt-1"
          @click="onClickApply"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="1.5"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
            />
          </svg>
        </ButtonComponent>
      </div>
      <p>
        <span
          class="bg-green-100 text-gray-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-gray-300"
          >{{ dateFilter }}</span
        >
      </p>
      <div class="mt-1">
        <VueApexCharts
          width="100%"
          height="300"
          type="bar"
          :series="series"
          :options="options"
        />
      </div>
    </div>
  </CardComponent>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import CardComponent from "./CardComponent.vue";
import DatePickerCom from "./DatePickerCom.vue";
import SelectMobileId from "./SelectMobileId.vue";
import ButtonComponent from "./ButtonComponent.vue";
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
import { getData } from "@/utils/fetch";

export default {
  components: {
    CardComponent,
    DatePickerCom,
    SelectMobileId,
    ButtonComponent,
    VueApexCharts,
  },
  setup() {
    const filter = reactive({
      date: [],
      mobileId: "",
    });
    const dateFilter = ref("");
    const dateNow = new Date();
    const messageSize = ref([]);
    const dateLabels = ref([]);

    onMounted(() => {
      filter.date = [dateNow, dateNow];
      dateFilter.value = `${moment(dateNow).format("DD MMM YYYY")} - ${moment(
        dateNow
      ).format("DD MMM YYYY")}`;
    });

    const series = ref([
      {
        name: "Total",
        data: [],
      },
    ]);

    const options = ref({
      chart: {
        type: "bar",
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      title: {
        text: "Total Message Size (KB)",
        align: "left",
        style: {
          fontWeight: "bold",
          fontSize: "15px",
          color: "black",
        },
      },
      noData: {
        text: "NO DATA FOUND",
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#3F2E3E",
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      xaxis: {
        categories: [
          "00-00-0000",
          "00-00-0000",
          "00-00-0000",
          "00-00-0000",
          "00-00-0000",
        ],
      },
      yaxis: [
        {
          decimalsInFloat: 2,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      theme: {
        palette: "palette2",
      },
    });

    const onClickApply = async () => {
      let dataParams = {};

      if (filter.mobileId != "") {
        dataParams = { ...dataParams, mobile_id: filter.mobileId };
      }

      dataParams = {
        ...dataParams,
        fromDate: moment(filter.date[0]).format("YYYY-MM-DD"),
        toDate: moment(filter.date[1]).format("YYYY-MM-DD"),
      };

      dateFilter.value = `${moment(filter.date[0]).format(
        "DD MMM YYYY"
      )} - ${moment(filter.date[1]).format("DD MMM YYYY")}`;

      const res = await getData("raw_skywave/messageSize", dataParams);
      for (let i = 0; i < res.data.length; i++) {
        messageSize.value.push(res.data[i].totalMessageSize / 1024);
        dateLabels.value.push(
          moment(res.data[i].message_date).format("DD-MM-YYYY")
        );
      }
      series.value[0].data = messageSize.value;
      options.value = {
        xaxis: {
          categories:
            dateLabels.value.length == 0
              ? [
                  "00-00-0000",
                  "00-00-0000",
                  "00-00-0000",
                  "00-00-0000",
                  "00-00-0000",
                ]
              : dateLabels.value,
        },
      };
      messageSize.value = [];
      dateLabels.value = [];
    };

    return {
      filter,
      dateFilter,
      series,
      options,
      onClickApply,
    };
  },
};
</script>
