<template>
  <SidebarRight>
    <div class="px-8">
      <TitleSubIconGap
        :title="data.email"
        titleColor="text-white"
        :subtitle="data.id"
        subtitleClor="text-white"
      >
        <IconUserCircle size="w-12 h-12" />
      </TitleSubIconGap>
      <TittleBetween addClass="pt-8 pb-5" title="Basic Info" />
    </div>
    <div class="flex flex-col space-y-5 px-8 [&_svg]:w-6 [&_svg]:h-6">
      <TextIconGroup
        :textWhite="true"
        :text="data.firstName + ' ' + data.lastName"
        textSize="text-md"
      >
        <IconId />
      </TextIconGroup>
      <TextIconGroup
        :textWhite="true"
        :text="data.role.toUpperCase()"
        textSize="text-md"
      >
        <IconRole />
      </TextIconGroup>
      <TextIconGroup
        :textWhite="true"
        :text="data.isEmailVerified == true ? 'Verified' : 'Not Verified'"
        textSize="text-md"
      >
        <IconCheck />
      </TextIconGroup>
      <TextIconGroup
        :textWhite="true"
        :text="date(data.createdAt)"
        textSize="text-md"
      >
        <IconDatePlus />
      </TextIconGroup>
      <TextIconGroup
        :textWhite="true"
        :text="date(data.updatedAt)"
        textSize="text-md"
      >
        <IconDate />
      </TextIconGroup>
    </div>
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import IconUserCircle from "@/components/icons/IconUserCircle.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconId from "@/components/icons/IconId.vue";
import IconRole from "@/components/icons/IconRole.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import IconDate from "@/components/icons/IconDate.vue";
import { useSidebarStore } from "@/store";
import { computed } from "vue";
import moment from "moment";

export default {
  components: {
    SidebarRight,
    TitleSubIconGap,
    IconUserCircle,
    TittleBetween,
    TextIconGroup,
    IconId,
    IconRole,
    IconCheck,
    IconDatePlus,
    IconDate,
  },
  setup() {
    const sidebar = useSidebarStore();
    const data = computed(() => sidebar.getData);
    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      data,
      date,
    };
  },
};
</script>

<style></style>
