<template>
  <div class="main">
    <HeaderTitle title="User">
      <IconUserGroup color="#14444f" size="w-11 h-11" />
    </HeaderTitle>
    <MainContent>
      <TableUser />
    </MainContent>
  </div>
  <DetailUser />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconUserGroup from "@/components/icons/IconUserGroup.vue";
import MainContent from "@/components/MainContent.vue";
import TableUser from "./TableUser.vue";
import DetailUser from "./DetailUser.vue";

export default {
  components: {
    HeaderTitle,
    IconUserGroup,
    MainContent,
    TableUser,
    DetailUser,
  },
};
</script>

<style></style>
