<template>
  <div class="pt-5 pb-5">
    <SearchInput class="w-6/12" @input="search" />
    <div
      v-if="result.rows?.length"
      class="pt-5 overflow-x-auto relative shadow-md rounded-lg"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b hover:bg-gray-100"
            v-for="db in result.rows"
            :key="db.username"
          >
            <th
              scope="row"
              class="py-3 px-6 font-medium flex items-center text-gray-900 space-x-3 whitespace-nowrap"
            >
              <div class="bg-greenSide rounded-full p-2">
                <IconUser size="w-6 h-6" />
              </div>
              <p class="text-md">{{ db.username }}</p>
            </th>
            <td class="py-3 px-3 whitespace-nowrap">{{ db.first_name }}</td>
            <td class="py-3 px-3">
              {{ db.last_name }}
            </td>
            <td class="py-3 px-3">
              {{ db.chat_id }}
            </td>
            <td class="py-3 px-3">
              <div
                class="flex justify-center space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
              >
                <div
                  class="bg-blue-800 rounded-full cursor-pointer inline-flex justify-center p-1"
                  @click="onClick(db)"
                >
                  <IconInfo />
                </div>
                <div
                  class="bg-red-700 rounded-full cursor-pointer inline-flex justify-center p-1"
                  @click="onDelete(db.username)"
                >
                  <IconTrash />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="result.loading" />
    <div v-if="result.rows?.length == 0" class="flex justify-center">
      <NoData size="w-7/12" />
    </div>
    <AlertErrorComponent v-if="result.error" :message="result.error.message" />
  </div>
  <DeleteConfirmation :username="selectUsername" @onDeleteData="delTeleUSer" />
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import IconUser from "@/components/icons/IconUser.vue";
import NoData from "@/components/NoData.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import { useModalStore, useSidebarStore, useTeleUserStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";

export default {
  props: {
    username: String,
  },
  components: {
    SearchInput,
    IconUser,
    NoData,
    LoadingLottie,
    AlertErrorComponent,
    IconInfo,
    PaginationComponent,
    IconTrash,
    DeleteConfirmation,
  },
  setup() {
    const sidebarStore = useSidebarStore();
    const teleUserStore = useTeleUserStore();
    const modal = useModalStore();
    const { result } = storeToRefs(teleUserStore);

    const offset = ref(0);
    const page = ref(1);
    const length = ref(0);
    const size = ref(10);
    const selectUsername = ref("");

    onMounted(async () => {
      await teleUserStore.getAll({ offset: offset.value });
      length.value = teleUserStore.result.count;
    });

    const search = async (value) => {
      offset.value = 0;
      await teleUserStore.getAll({
        offset: offset.value,
        q: value.target.value,
      });
      length.value = teleUserStore.result.count;
    };

    const onClick = (value) => {
      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.username) {
          state.id = value.username;
          state.data = value;
        }
      });
    };

    const onDelete = async (username) => {
      modal.showModal();
      selectUsername.value = username;
    };

    const delTeleUSer = async () => {
      await teleUserStore.deleteTele(selectUsername.value);
      length.value = teleUserStore.result.count;
    };

    const changePage = (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      teleUserStore.getAll({ offset: offset.value });
    };

    return {
      result,
      page,
      length,
      size,
      search,
      onClick,
      onDelete,
      changePage,
      delTeleUSer,
      selectUsername,
    };
  },
};
</script>
