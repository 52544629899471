<template>
  <SelectSearchLabel
    label="Mobile Id"
    :data="dataFetch"
    :optionsValue="'mobile_id'"
    :optionsKey="'mobile_id'"
    v-model:formControlName="formName"
    placeholder="Select Mobile Id"
    :labelColor="labelColor"
    :loading="loading"
  />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import SelectSearchLabel from "./SelectSearchWithLabel.vue";
import { getData } from "@/utils/fetch";

export default {
  components: {
    SelectSearchLabel,
  },
  props: {
    formControlName: [String, Number],
    labelColor: String,
  },
  setup(props, { emit }) {
    const dataFetch = ref([]);
    const loading = ref(false);

    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    const fetchData = async () => {
      loading.value = true;
      try {
        const result = await getData("raw_skywave/mobileId");
        dataFetch.value = result.data;
        loading.value = false;
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      dataFetch,
      loading,
      formName,
    };
  },
};
</script>

<style></style>
