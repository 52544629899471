import moment from "moment";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useDashboardStore = defineStore({
  id: "dashboard",
  state: () => ({
    filter1: reactive({
      date: [],
    }),
    filter2: reactive({
      date: [],
    }),
    dateFilter1: `${moment(new Date()).format("DD MMM YYYY")} - ${moment(
      new Date()
    ).format("DD MMM YYYY")}`,
    dateFilter2: `${moment(new Date()).format("DD MMM YYYY")} - ${moment(
      new Date()
    ).format("DD MMM YYYY")}`,
  }),
});
