<template>
  <div
    class="flex justify-end items-center p-6 space-x-2 rounded-b border-t border-gray-200"
  >
    <slot name="accept" />

    <ButtonComponentVue @click="modal.closeModal" :vClass="colorClose">
      {{ titleClose }}
    </ButtonComponentVue>
  </div>
</template>

<script>
import ButtonComponentVue from "./ButtonComponent.vue";
export default {
  components: {
    ButtonComponentVue,
  },
  props: {
    colorClose: {
      type: String,
      default: "btn-white",
    },
    titleClose: {
      type: String,
      default: "Close",
    },
  },
  setup() {
    const { useModalStore } = require("@/store");

    const modal = useModalStore();
    return {
      modal,
    };
  },
};
</script>
