<template>
  <div class="pt-5 flex justify-between">
    <SearchInput class="w-6/12" @input="search" />
    <ButtonComponent
      vClass="text-xs text-primary bg-gray-300 focus:ring-0 px-3 py-2"
      @click="modal.showModal(1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
        />
      </svg>
      New User
    </ButtonComponent>
    <AddUser :id="1" />
  </div>
  <div class="pt-5 pb-5">
    <div
      class="overflow-x-auto relative shadow-md rounded-lg"
      v-if="users.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b hover:bg-gray-100"
            v-for="data in users.rows"
            :key="data.id"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="flex items-center space-x-3">
                <div class="bg-greenSide rounded-full p-2">
                  <IconUser :color="'white'" :size="'w-5 h-5'" />
                </div>
                <p class="text-md">{{ data.firstName }}</p>
              </div>
            </th>
            <td class="py-3 px-2 md:px-0">{{ data.lastName }}</td>
            <td class="py-3 px-2 md:px-0">{{ data.email }}</td>
            <td class="py-3 px-2 md:px-0 text-center">
              <div v-if="data.role == 'user'">
                <span
                  class="bg-indigo-200 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-indigo-700 dark:text-indigo-300 border border-indigo-200"
                  >{{ data.role.toUpperCase() }}</span
                >
              </div>
              <div v-else>
                <span
                  class="bg-red-200 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-red-700 dark:text-red-400 border border-red-200"
                  >{{ data.role.toUpperCase() }}</span
                >
              </div>
            </td>
            <td class="py-3 px-3 md:px-0">
              <div
                class="flex justify-center space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
              >
                <div
                  class="bg-blue-800 rounded-full cursor-pointer p-1"
                  @click="onClick(data)"
                >
                  <IconInfo />
                </div>
                <div
                  class="bg-amber rounded-full cursor-pointer p-1"
                  @click="onEdit(data.id)"
                >
                  <IconEdit color="fill-white" />
                </div>

                <div
                  class="bg-red-700 rounded-full cursor-pointer p-1"
                  @click="onDelete(data.id)"
                >
                  <IconTrash />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="users.loading" />
    <div class="flex justify-center" v-if="users.rows?.length == 0">
      <NoData size="w-7/12" />
    </div>
    <AlertErrorComponent v-if="users.error" :message="users.error.message" />
  </div>
  <EditUser :id="2" />
  <DeleteConfirmation
    :id="3"
    :userId="selectUserId"
    @onDeleteData="deleteUser"
  />
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import AddUser from "./AddUser.vue";
import EditUser from "./EditUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useModalStore, useSidebarStore, useUserStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  props: {
    userId: String,
  },
  components: {
    SearchInput,
    ButtonComponent,
    IconUser,
    IconInfo,
    IconEdit,
    IconTrash,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditUser,
    AddUser,
    PaginationComponent,
    DeleteConfirmation,
  },
  setup() {
    const modal = useModalStore();
    const userStore = useUserStore();
    const sidebarStore = useSidebarStore();
    const { users } = storeToRefs(userStore);

    const size = ref(10);
    const page = ref(1);
    const length = ref(0);
    const offset = ref(0);
    const selectUserId = ref("");

    onMounted(async () => {
      await userStore.getAll({ offset: offset.value });
      length.value = userStore.users.count;
    });

    const search = async (value) => {
      offset.value = 0;
      await userStore.getAll({
        offset: offset.value,
        q: value.target.value,
      });
      length.value = userStore.users.count;
    };

    const onClick = (value) => {
      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.id) {
          state.id = value.id;
          state.data = value;
        }
      });
    };

    const changePage = (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      userStore.getAll({ offset: offset.value });
    };

    const onEdit = async (id) => {
      modal.showModal(2);
      await userStore.userById(id);
    };

    const onDelete = async (id) => {
      modal.showModal(3);
      selectUserId.value = id;
    };

    const deleteUser = async () => {
      await userStore.deleteUser(selectUserId.value);
      length.value = userStore.users.count;
    };

    return {
      users,
      size,
      length,
      page,
      modal,
      search,
      onClick,
      changePage,
      onEdit,
      onDelete,
      deleteUser,
      selectUserId,
    };
  },
};
</script>

<style></style>
