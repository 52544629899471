<template>
  <CardComponent :scale="true">
    <h3 class="text-center text-base font-bold">Welcome to Seahub Messaging</h3>
    <h2 class="text-center text-2xl text-greenSide font-bold mt-4">
      {{ name }}
    </h2>
    <div class="flex justify-between gap-3 md:gap-5 mt-6">
      <TittleSub
        :title="email"
        :titleColor="'text-gray-700'"
        :subtitle="date"
      />
      <ButtonComponent vClass="bg-darkGreen" @click="$emit('viewDetail')"
        >Detail</ButtonComponent
      >
    </div>
  </CardComponent>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import TittleSub from "./TittleSub.vue";
import ButtonComponent from "./ButtonComponent.vue";

export default {
  components: {
    CardComponent,
    TittleSub,
    ButtonComponent,
  },
  props: {
    name: String,
    email: String,
    date: String,
  },
};
</script>
