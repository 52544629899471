<template>
  <div class="main">
    <HeaderTitle title="Logbook">
      <IconLogBook size="w-8 h-8" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <TableLogbook />
    </MainContent>
  </div>
  <LogbookDetail />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconLogBook from "@/components/icons/IconLogBook.vue";
import MainContent from "@/components/MainContent.vue";
import TableLogbook from "./TableLogbook.vue";
import LogbookDetail from "./LogbookDetail.vue";

export default {
  components: {
    HeaderTitle,
    IconLogBook,
    MainContent,
    TableLogbook,
    LogbookDetail,
  },
};
</script>

<style></style>
