<template>
  <div class="main">
    <HeaderTitle title="Raw Skywave Data">
      <IconChat size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <TableRawSkywave />
    </MainContent>
  </div>
  <RawSkywaveDetail />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconChat from "@/components/icons/IconChat.vue";
import MainContent from "@/components/MainContent.vue";
import TableRawSkywave from "./TableRawSkywave.vue";
import RawSkywaveDetail from "./RawSkywaveDetail.vue";
import { useModalStore } from "@/store";

export default {
  components: {
    HeaderTitle,
    IconChat,
    MainContent,
    TableRawSkywave,
    RawSkywaveDetail,
  },
  setup() {
    const modal = useModalStore();

    return {
      modal,
    };
  },
};
</script>
