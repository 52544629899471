<template>
  <aside
    class="fixed inset-y-0 z-20 duration-200 flex-shrink-0 h-screen overflow-hidden bg-greenSide md:static focus:outline-none"
    :class="[open ? 'w-20' : 'w-0 md:w-20']"
  >
    <nav class="flex flex-col flex-shrink-0 h-full px-4 py-4 border-r">
      <div class="flex flex-col items-center justify-center flex-1 space-y-6">
        <navigation-link routeLink="/dashboard">
          <IconHome />
        </navigation-link>
        <navigation-link routeLink="/tele_user">
          <IconTelegram />
        </navigation-link>
        <navigation-link routeLink="/raw_skywave">
          <IconChat />
        </navigation-link>
        <navigation-link routeLink="/logbook">
          <IconLogBook />
        </navigation-link>
        <navigation-link routeLink="/user" v-show="role == 'admin'">
          <IconUserGroup />
        </navigation-link>
      </div>
      <div class="flex items-center justify-center flex-shrink-0 mt-4">
        <a
          href="#"
          @click="authStore.logout()"
          class="p-2 text-indigo-400 transition-colors duration-200 rounded-md bg-indigo-50 hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:bg-indigo-100 focus:ring-indigo-800"
        >
          <span class="sr-only">Logout</span>
          <svg
            class="w-7 h-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
        </a>
      </div>
    </nav>
  </aside>
  <div
    class="fixed inset-y-0 z-10 duration-500 flex-shrink-0 w-full overflow-hidden h-screen bg-primary bg-opacity-30 backdrop-blur-sm border-r md:static focus:outline-none"
    :class="[open ? 'block md:hidden' : 'hidden md:hidden']"
  >
    <a
      href="#"
      @click="openNav"
      class="p-2 text-indigo-400 absolute right-5 top-5 transition-colors duration-200 rounded-md bg-indigo-50 hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:bg-indigo-100 focus:ring-indigo-800"
    >
      <span class="sr-only">close</span>
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        class="w-7 h-7"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </a>
  </div>
</template>

<script>
import NavigationLink from "@/components/NavigationLink.vue";
import IconHome from "./icons/IconHome.vue";
import IconTelegram from "./icons/IconTelegram.vue";
import IconChat from "./icons/IconChat.vue";
import IconUserGroup from "./icons/IconUserGroup.vue";
import IconLogBook from "./icons/IconLogBook.vue";
import { useAuthStore, useNavigationStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  name: "NavigationWeb",
  components: {
    NavigationLink,
    IconHome,
    IconTelegram,
    IconChat,
    IconUserGroup,
    IconLogBook,
  },
  setup() {
    const authStore = useAuthStore();
    const navigation = useNavigationStore();
    const { open } = storeToRefs(navigation);
    const role = ref("");

    const openNav = () => {
      navigation.open = !navigation.open;
    };

    onMounted(() => {
      if (authStore.user.currUser != null) {
        role.value = authStore.user.currUser.role;
      }
    });

    return {
      authStore,
      openNav,
      open,
      role,
    };
  },
};
</script>
<style></style>
