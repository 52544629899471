<template>
  <div class="pt-5 pb-5">
    <SearchInput @input="search" class="w-6/12" />
    <div
      class="overflow-x-auto relative shadow-md rounded-lg pt-5"
      v-if="logbook.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b hover:bg-gray-100"
            v-for="logbook in logbook.rows"
            :key="logbook.message_id"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="inline-flex items-center space-x-2">
                <div class="bg-greenSide rounded-full p-2">
                  <IconSend :size="'w-5 h-5'" />
                </div>
                <p class="text-md">
                  {{ JSON.parse(logbook.message_json).Payload.Fields[0].Value }}
                </p>
              </div>
            </th>
            <td class="py-3 px-3">
              <div class="inline-flex items-center space-x-2">
                <div class="bg-greenSide rounded-full p-2">
                  <IconReceived :color="'white'" :size="'w-5 h-5'" />
                </div>
                <p class="text-md">
                  {{ JSON.parse(logbook.message_json).Payload.Fields[1].Value }}
                </p>
              </div>
            </td>
            <td class="py-3 whitespace-nowrap">
              {{ JSON.parse(logbook.message_json).Payload.Fields[2].Value }}
            </td>
            <td class="py-3">
              {{ JSON.parse(logbook.message_json).OTAMessageSize }}
            </td>
            <td class="py-3 px-3">
              {{ JSON.parse(logbook.message_json).RegionName }}
            </td>
            <td class="py-4 px-6">
              <div
                class="bg-blue-800 rounded-full inline-flex items-center cursor-pointer p-1"
                @click="onClick(logbook)"
              >
                <IconInfo size="w-3 h-3" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:size="size"
        v-model:length="length"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="logbook.loading" />
    <div class="flex justify-center" v-if="logbook.rows?.length == 0">
      <NoData size="w-7/12" />
    </div>
    <AlertErrorComponent
      v-if="logbook.error"
      :message="logbook.error.message"
    />
  </div>
</template>

<script>
import IconSend from "@/components/icons/IconSend.vue";
import IconReceived from "@/components/icons/IconReceived.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import SearchInput from "@/components/SearchInput.vue";
import { useLogbookStore, useSidebarStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  components: {
    IconSend,
    IconReceived,
    IconInfo,
    PaginationComponent,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    SearchInput,
  },
  setup() {
    const sidebar = useSidebarStore();
    const logbookStore = useLogbookStore();
    const { logbook, dataLogbook } = storeToRefs(logbookStore);

    const offset = ref(0);
    const size = ref(10);
    const length = ref(0);
    const page = ref(1);

    onMounted(async () => {
      await logbookStore.getAll({ offset: offset.value });
      length.value = logbookStore.logbook.count;
    });

    const onClick = (value) => {
      sidebar.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.message_id) {
          state.id = value.message_id;
          state.data = value;
        }
      });
    };

    const search = async (value) => {
      offset.value = 0;
      await logbookStore.getAll({
        offset: offset.value,
        q: value.target.value,
      });
      length.value = logbookStore.logbook.count;
    };

    const changePage = (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      logbookStore.getAll({ offset: offset.value });
    };

    return {
      logbook,
      dataLogbook,
      size,
      length,
      page,
      onClick,
      changePage,
      search,
    };
  },
};
</script>

<style></style>
