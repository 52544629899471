<template>
  <SidebarRight>
    <div class="px-8">
      <TitleSubIcon
        :title="data.message_id"
        titleColor="text-white"
        :subtitle="data.mobile_id"
        :subtitle2="JSON.parse(data.message_json).SIN"
        :subtitle3="JSON.parse(data.message_json).Payload.MIN"
        subtitleColor="text-white"
      >
        <IconChat color="white" />
      </TitleSubIcon>
      <TittleBetween addClass="pt-8 pb-5" title="Basic Info" />
    </div>

    <div class="flex flex-col px-8 space-y-5 [&_svg]:w-6 [&_svg]:h-6">
      <TextIconGroup
        :text="data.message_size"
        :textWhite="true"
        textSize="text-md"
      >
        <IconMemory color="white" />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).Payload.Fields[0].Value"
        :textWhite="true"
        textSize="text-md"
      >
        <IconSend />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).Payload.Fields[1].Value"
        :textWhite="true"
        textSize="text-md"
      >
        <IconReceived />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).RegionName"
        :textWhite="true"
        textSize="text-md"
      >
        <IconRegionName />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).MobileOwnerID"
        :textWhite="true"
        :textSize="'text-md'"
      >
        <IconId />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).CustomerID"
        :textWhite="true"
        :textSize="'text-md'"
      >
        <IconUser />
      </TextIconGroup>
      <TextIconGroup
        :text="JSON.parse(data.message_json).Transport"
        :textWhite="true"
        :textSize="'text-md'"
      >
        <IconTransport />
      </TextIconGroup>
      <TextIconGroup
        :text="date(JSON.parse(data.message_json).MessageUTC)"
        :textWhite="true"
        textSize="text-md"
      >
        <IconDatePlus />
      </TextIconGroup>
      <TextIconGroup
        :text="date(JSON.parse(data.message_json).ReceiveUTC)"
        :textWhite="true"
        textSize="text-md"
      >
        <IconDate />
      </TextIconGroup>
    </div>
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIcon from "@/components/TitleSubIcon.vue";
import IconChat from "@/components/icons/IconChat.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconMemory from "@/components/icons/IconMemory.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconReceived from "@/components/icons/IconReceived.vue";
// import IconMessage from "@/components/icons/IconMessage.vue";
import IconDate from "@/components/icons/IconDate.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import IconTransport from "@/components/icons/IconTransport.vue";
import IconRegionName from "@/components/icons/IconRegionName.vue";
import IconId from "@/components/icons/IconId.vue";
import IconUser from "@/components/icons/IconUser.vue";
import { useSidebarStore } from "@/store";
import { computed } from "vue";
import moment from "moment";

export default {
  components: {
    SidebarRight,
    TitleSubIcon,
    IconChat,
    TittleBetween,
    TextIconGroup,
    IconMemory,
    IconSend,
    IconReceived,
    // IconMessage,
    IconDate,
    IconDatePlus,
    IconTransport,
    IconRegionName,
    IconId,
    IconUser,
  },
  setup() {
    const sidebar = useSidebarStore();
    const data = computed(() => sidebar.getData);
    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };
    const dateNoTime = (date) => {
      return moment(date).format("DD MMMM YYYY");
    };

    return {
      data,
      date,
      dateNoTime,
    };
  },
};
</script>

<style></style>
