<template>
  <div class="pt-5 pb-5">
    <SearchInput class="w-6/12" @input="search" />
    <div
      class="pt-5 overflow-x-auto relative shadow-md rounded-lg"
      v-if="skywave.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b hover:bg-gray-100"
            v-for="data in skywave.rows"
            :key="data.message_id"
          >
            <th scope="row" class="py-3 px-3 md:px-6 font-medium text-gray-900">
              <div class="inline-flex items-center space-x-2">
                <div class="bg-greenSide rounded-full p-2">
                  <IconSend :color="'white'" :size="'w-5 h-5'" />
                </div>
                <p class="text-md">
                  {{ JSON.parse(data.message_json).Payload.Fields[0].Value }}
                </p>
              </div>
            </th>
            <td class="py-3 px-3 md:px-6">
              <div class="inline-flex items-center space-x-2">
                <div class="bg-greenSide rounded-full p-2">
                  <IconReceive :color="'white'" :size="'w-5 h-5'" />
                </div>
                <p class="text-md">
                  {{ JSON.parse(data.message_json).Payload.Fields[1].Value }}
                </p>
              </div>
            </td>
            <td class="py-3 whitespace-nowrap">
              {{ JSON.parse(data.message_json).Payload.Fields[2].Value }}
            </td>
            <td class="py-3">
              {{ JSON.parse(data.message_json).OTAMessageSize }}
            </td>
            <td class="py-3 px-3">
              {{ JSON.parse(data.message_json).RegionName }}
            </td>
            <td class="py-4 px-6">
              <div
                class="bg-blue-800 rounded-full inline-flex items-center cursor-pointer p-1"
                @click="onClick(data)"
              >
                <IconInfo size="w-3 h-3" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:size="size"
        v-model:length="length"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="skywave.loading" />
    <div class="flex justify-center" v-if="skywave.rows?.length == 0">
      <NoData size="w-7/12" />
    </div>
    <AlertErrorComponent
      v-if="skywave.error"
      :message="skywave.error.message"
    />
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconReceive from "@/components/icons/IconReceived.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import { onMounted, ref } from "vue";
import { useRawSkywaveStore, useSidebarStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: {
    SearchInput,
    IconSend,
    IconReceive,
    IconInfo,
    PaginationComponent,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
  },
  setup() {
    const offset = ref(0);
    const size = ref(10);
    const length = ref(0);
    const page = ref(1);

    const sidebar = useSidebarStore();
    const rawskywaveStore = useRawSkywaveStore();
    const { skywave } = storeToRefs(rawskywaveStore);
    onMounted(async () => {
      await rawskywaveStore.getAll({ offset: offset.value });
      length.value = rawskywaveStore.skywave.count;
    });

    const changePage = (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      rawskywaveStore.getAll({ offset: offset.value });
    };

    const onClick = (value) => {
      sidebar.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.message_id) {
          state.id = value.message_id;
          state.data = value;
        }
      });
    };

    const search = async (value) => {
      offset.value = 0;
      await rawskywaveStore.getAll({
        offset: offset.value,
        q: value.target.value,
      });
      length.value = rawskywaveStore.skywave.count;
    };

    return {
      size,
      length,
      page,
      skywave,
      changePage,
      onClick,
      search,
    };
  },
};
</script>

<style></style>
