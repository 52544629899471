<template>
  <div class="main">
    <HeaderTitle title="Telegram Message">
      <IconTeleMessage />
    </HeaderTitle>
    <MainContent>
      <div class="flex flex-col md:flex-row space-y-5 md:space-y-0">
        <div class="basis-5/12 md:border-r-2 md:border-gray-300">
          <ContactListView />
        </div>
        <div class="basis-full md:pl-8">
          <ChatView />
        </div>
      </div>
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconTeleMessage from "@/components/icons/IconTeleMessage.vue";
import MainContent from "@/components/MainContent.vue";
import ContactListView from "./ContactListView.vue";
import ChatView from "./ChatView.vue";

export default {
  components: {
    HeaderTitle,
    IconTeleMessage,
    MainContent,
    ContactListView,
    ChatView,
  },
};
</script>

<style></style>
