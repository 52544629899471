import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import axios from "../config";
import { useErrorStore } from "./error.store";
import { reactive } from "vue";
import { useModalStore } from "./modal.store";

let debouncedFetch = debounce(getData, 500);

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    users: {},
    byId: reactive({
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    }),
    id: "",
    total: {},
  }),
  getters: {
    getDataUser(state) {
      return state.users.data;
    },
  },
  actions: {
    async getAll(params) {
      this.users = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        name: params?.name,
        role: params?.role,
        order: params?.order || "firstName",
        ordertype: params?.order || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("users", dataparams);
        this.users = { ...data.data };
      } catch (error) {
        this.users = { error };
      }
    },

    async addUser(firstName, lastName, email, password, role) {
      try {
        await axios.post("users", {
          firstName: firstName,
          lastName: lastName ? lastName : " ",
          email: email,
          password: password,
          role: role,
        });
        this.getAll({});
      } catch (error) {
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error;
        console.log(error);
      }
    },

    async userById(id) {
      if (id) {
        this.id = id;
        try {
          const res = await axios.get(`users/${id}`);
          this.byId.firstName = res.data.firstName;
          this.byId.lastName = res.data.lastName;
          this.byId.email = res.data.email;
          this.byId.role = res.data.role;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateUser(firstName, lastName, email, role) {
      try {
        await axios.patch(`users/${this.id}`, {
          firstName: firstName,
          lastName: lastName ? lastName : " ",
          email: email,
          role: role,
        });
        this.getAll({});
      } catch (error) {
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error.response.data.message;
      }
    },

    async deleteUser(id) {
      const modal = useModalStore();
      try {
        await axios.delete(`users/${id}`);
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error.response.data.message;
        console.log(error);
      }
    },

    async countUser() {
      this.total = { loading: true };
      try {
        const data = await axios.get("users/count");
        this.total = { ...data.data };
      } catch (error) {
        this.total = error;
      }
    },
  },
});
