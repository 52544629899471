<template>
  <div
    class="w-full rounded-lg bg-cardColor border border-greenSide shadow-md transition duration-300"
    :class="[{ 'hover:scale-105': scale }]"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    scale: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
