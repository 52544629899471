<template>
  <SidebarRight>
    <div class="px-8">
      <TitleSubIconGap
        :title="data.username"
        titleColor="text-white"
        :subtitle="data.first_name"
        :subtitle2="data.last_name"
        subtitleColor="text-white"
      >
        <IconUser size="w-10 h-10" />
      </TitleSubIconGap>

      <TittleBetween title="Basic Info" addClass="pt-8 pb-5" />
    </div>
    <div class="flex flex-col px-8 space-y-5 [&_svg]:w-6 [&_svg]:h-6">
      <TextIconGroup :text="data.chat_id" :textWhite="true" textSize="text-md">
        <IconChat />
      </TextIconGroup>

      <TextIconGroup
        :text="data.parent_id != null ? data.parent_id : '0'"
        :textWhite="true"
        textSize="text-md"
      >
        <IconId />
      </TextIconGroup>

      <TextIconGroup
        :text="data.status != null ? data.status : '0'"
        :textWhite="true"
        textSize="text-md"
      >
        <IconCheck />
      </TextIconGroup>

      <TextIconGroup
        :text="date(data.createdAt)"
        :textWhite="true"
        textSize="text-md"
      >
        <IconDatePlus />
      </TextIconGroup>

      <TextIconGroup
        :text="date(data.updatedAt)"
        :textWhite="true"
        textSize="text-md"
      >
        <IconDate />
      </TextIconGroup>
    </div>
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconId from "@/components/icons/IconId.vue";
import IconDate from "@/components/icons/IconDate.vue";
import IconChat from "@/components/icons/IconChat.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import { useSidebarStore } from "@/store";
import { computed } from "vue";
import moment from "moment";

export default {
  components: {
    SidebarRight,
    IconUser,
    IconId,
    TextIconGroup,
    IconDate,
    IconChat,
    IconCheck,
    TittleBetween,
    TitleSubIconGap,
    IconDatePlus,
  },
  setup() {
    const sidebar = useSidebarStore();
    const data = computed(() => sidebar.getData);

    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      data,
      date,
    };
  },
};
</script>
