<template>
  <div class="w-full h-[510px] border flex flex-col">
    <!-- Header -->
    <div
      class="py-2 px-3 bg-greenSide flex flex-row justify-between items-center"
    >
      <div class="flex items-center">
        <div>
          <img
            class="w-10 h-10 rounded-full"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcEAWyDow0ofnuRYaL-6C4Ivfz-CP-qoAU1g&usqp=CAU"
          />
        </div>
        <div class="ml-4">
          <p class="text-white">Seahub</p>
        </div>
      </div>
    </div>

    <!-- Messages -->
    <div class="flex-1 overflow-y-auto" style="background-color: #dad3cc">
      <div class="py-2 px-3 mt-2">
        <div class="flex mb-2">
          <div class="rounded py-2 px-3" style="background-color: #f2f2f2">
            <p class="text-sm mt-1">Hello</p>
            <p class="text-right text-xs text-gray-600 mt-1">12:45 pm</p>
          </div>
        </div>

        <div class="flex justify-end mb-2">
          <div class="rounded py-2 px-3" style="background-color: #e2f7cb">
            <p class="text-sm mt-1">Hi guys.</p>
            <p class="text-left text-xs text-gray-600 mt-1">12:45 pm</p>
          </div>
        </div>

        <div class="flex flex-col mb-2" v-if="data">
          <div v-for="(data, index) in data" :key="index">
            <div class="flex mb-2" v-if="index % 2 == 0">
              <div class="rounded py-2 px-3" style="background-color: #f2f2f2">
                <p class="text-sm mt-1">{{ JSON.parse(data).msg }}</p>
                <p class="text-right text-xs text-gray-600 mt-1">
                  {{ JSON.parse(data).date ? JSON.parse(data).date : "--:--" }}
                </p>
              </div>
            </div>

            <div>
              <div class="flex mb-2 justify-end" v-if="index % 2 != 0">
                <div
                  class="rounded py-2 px-3"
                  style="background-color: #e2f7cb"
                >
                  <p class="text-sm mt-1">{{ JSON.parse(data).msg }}</p>
                  <p class="text-left text-xs text-gray-600 mt-1">
                    {{
                      JSON.parse(data).date ? JSON.parse(data).date : "--:--"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!----->
      </div>
    </div>

    <!-- Input -->
    <div class="bg-gray-300 px-4 py-4 flex items-center">
      <div class="flex-1 mx-3">
        <input
          class="w-full border border-gray-300 rounded px-2 py-2"
          type="text"
          placeholder="Type a message"
          v-model="message"
        />
      </div>
      <div class="cursor-pointer" @click="publish">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#263238"
          stroke-opacity=".45"
          class="w-7 h-7"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      </div>
      <!---->
    </div>
  </div>
</template>

<script>
import mqtt from "precompiled-mqtt";
import { onMounted, reactive, ref } from "vue";
import moment from "moment";

export default {
  setup() {
    const message = ref("");
    const date = moment(new Date()).format("hh:mm a");
    const data = ref([]);
    const clien = ref({ connected: false });

    const options = reactive({
      clientId: "seahub" + Math.random().toString(16).substring(2, 8),
      clean: true,
      connectTimeOut: 1 * 1000,
      keepAlive: 1,
      reconnectPeriod: 0,
    });

    const URL = "ws://test.mosquitto.org:8081";
    // const URL = "ws://192.168.43.xx:1234";

    const createConnection = () => {
      try {
        clien.value = mqtt.connect(URL, options);

        clien.value.on("connect", () => {
          console.log("Connected to mqtt success");
        });

        clien.value.on("error", (error) => {
          console.log("error : ", error);
        });
      } catch (error) {
        console.log("connection error : ", error);
      }
    };

    const disconnect = () => {
      if (clien.value.connected) {
        try {
          clien.value.end(false, () => {
            console.log("disconnected");
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    const subscribe = () => {
      const topic = "message";
      clien.value.subscribe(topic, (error) => {
        if (error) {
          console.log("subscribe error : ", error);
        }
        clien.value.on("message", (topic, message) => {
          console.log("message subscribe ", message.toString());
          data.value.push(message.toString());
        });
      });
    };

    const publish = () => {
      const topic = "message";
      const payload = `{"msg": "${message.value}", "date": "${date}"}`;
      if (!clien.value.connected) {
        createConnection();
        subscribe();
      }
      clien.value.publish(topic, payload, (error) => {
        if (error) {
          console.log("publish error : ", error);
        } else {
          console.log(`publish message ${payload} to topic ${topic}`);
          // data.value = payload;
        }
      });
      message.value = "";
    };

    onMounted(() => {
      createConnection();
      subscribe();
    });

    return {
      message,
      createConnection,
      publish,
      subscribe,
      disconnect,
      data,
    };
  },
};
</script>

<style></style>
