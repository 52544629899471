<template>
  <div class="main">
    <HeaderTitle title="Dashboard">
      <IconHome size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="grid grid-cols-1 md:grid-cols-10 py-3 px-4 pt-5">
        <div class="md:col-start-1 md:col-end-4">
          <CardUserLogin
            :name="loggedIn.firstName"
            :email="loggedIn.email"
            :date="date(loggedIn.createdAt)"
            @viewDetail="viewUserDetail"
          />
          <UserLoginDetail />
        </div>

        <div class="md:col-start-5 md:col-end-11 space-y-2">
          <h1 class="text-lg font-bold py-3 md:py-0">Information</h1>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5">
            <CardDetail
              :loading="result.loading"
              :menu="'Telegram User'"
              :detail="result.count"
              @viewMore="viewTeleUser"
            />
            <CardDetail
              :loading="skywave.loading"
              :background="'bg-green-100'"
              :text="'text-green-900'"
              :menu="'Message'"
              :detail="skywave.count"
              :bgButton="'bg-green-200'"
              :textColor="'text-green-800'"
              :hover="'hover:bg-green-300'"
              @viewMore="viewRawSkywave"
            />
            <CardDetail
              :loading="logbook.loading"
              :background="'bg-red-100'"
              :text="'text-red-900'"
              :menu="'Logbook'"
              :detail="logbook.count"
              :bgButton="'bg-red-200'"
              :textColor="'text-red-800'"
              :hover="'hover:bg-red-300'"
              @viewMore="viewLogbook"
            />
            <CardTotalUser
              :menu="'User'"
              :detail="total.count"
              :loading="total.loading"
              @viewMore="viewUser"
            />
          </div>
        </div>
      </div>
      <div class="mt-3 px-4">
        <h1 class="text-lg font-bold">Total Message Size</h1>
      </div>
      <div
        class="py-3 px-4 flex flex-col space-y-5 md:space-y-0 md:flex-row space-x-0 md:space-x-8"
      >
        <div class="md:flex-0">
          <CardMessageSize />
        </div>
        <div class="md:flex-1">
          <CardMessageChart />
        </div>
      </div>
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconHome from "@/components/icons/IconHome.vue";
import MainContent from "@/components/MainContent.vue";
import CardUserLogin from "@/components/CardUserLogin.vue";
import CardDetail from "@/components/CardDetail.vue";
import UserLoginDetail from "./UserLoginDetail.vue";
import CardTotalUser from "@/components/CardTotalUser.vue";
import CardMessageSize from "@/components/CardMessageSize.vue";
import CardMessageChart from "@/components/CardMessageChart.vue";
import { storeToRefs } from "pinia";
import {
  useAuthStore,
  useTeleUserStore,
  useModalStore,
  useRawSkywaveStore,
  useUserStore,
  useLogbookStore,
} from "@/store";
import { onMounted, ref } from "vue";
import router from "@/router";
import moment from "moment";

export default {
  components: {
    HeaderTitle,
    IconHome,
    MainContent,
    CardUserLogin,
    CardDetail,
    UserLoginDetail,
    CardTotalUser,
    CardMessageSize,
    CardMessageChart,
  },
  setup() {
    const authStore = useAuthStore();
    const teleUserStore = useTeleUserStore();
    const rawSkywaveStore = useRawSkywaveStore();
    const userStore = useUserStore();
    const logbookStore = useLogbookStore();
    const modal = useModalStore();
    const { result } = storeToRefs(teleUserStore);
    const { skywave } = storeToRefs(rawSkywaveStore);
    const { total } = storeToRefs(userStore);
    const { logbook } = storeToRefs(logbookStore);
    const loggedIn = ref("");

    onMounted(() => {
      teleUserStore.getAll({});
      rawSkywaveStore.getAll({});
      userStore.countUser();
      if (authStore.user.currUser != null) {
        loggedIn.value = authStore.user.currUser;
      }
      logbookStore.getAll({});
    });

    const viewTeleUser = () => {
      router.push(`/tele_user`);
    };

    const viewRawSkywave = () => {
      router.push(`/raw_skywave`);
    };
    const viewUser = () => {
      router.push(`/user`);
    };

    const viewUserDetail = () => {
      modal.showModal();
    };

    const viewLogbook = () => {
      router.push(`/logbook`);
    };

    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      result,
      skywave,
      total,
      viewTeleUser,
      date,
      viewUserDetail,
      viewRawSkywave,
      viewUser,
      loggedIn,
      logbook,
      viewLogbook,
    };
  },
};
</script>
