<template>
  <CardNoPadding :scale="true">
    <div class="px-2.5 py-6">
      <h1 class="text-center md:text-center">
        <span
          class="text-center text-xs font-medium px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
          :class="[background, text]"
          >{{ menu }}</span
        >
      </h1>
      <div class="mt-3 flex flex-col space-y-3">
        <LoadingLoad v-if="loading" />
        <h1 v-else class="text-center text-1xl">{{ detail }}</h1>
      </div>
    </div>
    <button
      class="w-full justify-between rounded-b-lg font-bold text-sm py-2 pl-2 inline-flex"
      :class="[bgButton, textColor, hover]"
      @click="$emit('viewMore')"
    >
      <span>View More</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-5 h-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  </CardNoPadding>
</template>

<script>
import CardNoPadding from "./CardNoPadding.vue";
import LoadingLoad from "./LoadingLoad.vue";
export default {
  props: {
    menu: String,
    detail: [String, Number],
    background: {
      type: String,
      default: "bg-blue-100",
    },
    text: {
      type: String,
      default: "text-blue-900",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bgButton: {
      type: String,
      default: "bg-blue-200",
    },
    textColor: {
      type: String,
      default: "text-blue-800",
    },
    hover: {
      type: String,
      default: "hover:bg-blue-300",
    },
  },
  components: {
    CardNoPadding,
    LoadingLoad,
  },
};
</script>

<style></style>
