<template>
  <router-link
    :to="routeLink"
    active-class="bg-amber "
    @click="openNav"
    class="p-2 text-white transition-colors duration-200 rounded-md"
  >
    <slot />
  </router-link>
</template>

<script>
import { useNavigationStore } from "@/store";
export default {
  props: {
    routeLink: String,
  },
  setup() {
    const navigation = useNavigationStore();
    const openNav = () => {
      navigation.open = false;
    };

    return {
      openNav,
    };
  },
};
</script>
