<template>
  <div class="w-full h-[510px] border flex flex-col">
    <div
      class="h-14 py-2 px-3 bg-greenSide flex flex-row justify-between items-center"
    >
      <div class="flex items-center">
        <h1 class="text-lg text-white sr-only">Contact</h1>
      </div>
    </div>

    <div class="bg-white overflow-y-auto flex-1 border-b border-gray-300">
      <div
        class="px-3 py-2 flex items-center cursor-pointer border-b border-gray-300"
        v-for="index in 10"
        :key="index"
      >
        <img
          class="h-12 w-12 rounded-full"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcEAWyDow0ofnuRYaL-6C4Ivfz-CP-qoAU1g&usqp=CAU"
        />
        <div class="ml-4 flex-1 py-4">
          <div class="flex justify-between">
            <p class="text-gray-600">Seahub</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
